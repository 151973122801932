<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"><h4>Livro Diário</h4></el-row>
          </el-col>
          <el-col :md="16">
            <el-row type="flex" justify="end" align="center" :gutter="8">
              <el-col :md="8" :sm="8" :xs="10">
                <el-date-picker
                  size="medium"
                  v-model="day"
                  format="DD/MM/YYYY"
                ></el-date-picker>
              </el-col>
              <el-dropdown
                type="primary"
                size="medium"
                trigger="click"
                class="mt-1"
                @command="(v) => executeUserCommand(v)"
              >
                <el-button size="medium" type="primary" style="margin-top: 5px">
                  Imprimir
                  <el-icon class="el-icon-arrow-down"></el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="OrderByCreatedAt"
                      >Ordenado por criação</el-dropdown-item
                    >
                    <el-dropdown-item command="OrderByInvoiceId"
                      >Ordenado por romaneio</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </el-row>
          </el-col>
        </el-row>
      </template>
      <el-table
        stripe
        :cell-style="() => 'text-align:center;'"
        :data="Orders"
        style="width: 100%; z-index: 0"
      >
        <el-table-column label="romaneio" prop="invoice_id" width="100">
          <template #default="{ row: order }">
            <base-input
              v-if="canUpdate(order)"
              v-model="order.invoice_id"
              v-on:keyup.enter="
                (v) =>
                  updateOrder({ uid: order.uid, invoice_id: v?.target?.value })
              "
            ></base-input>

            <span v-on:click="allowUserUpdate(order.uid)" v-else>
              {{ order.invoice_id || "NÃO INFORMADO" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="código"
          prop="seq"
          width="100"
        ></el-table-column>
        <el-table-column label="pedido" prop="code"></el-table-column>
        <el-table-column prop="driver.employee.name" label="vendedor">
        </el-table-column>
        <el-table-column prop="client.name" label="cliente">
          <template #default="{ row: order }">
            {{ order.client?.legal?.social_name || order.client?.name }}
          </template>
        </el-table-column>
        <el-table-column prop="client.legal.company_name" label="razão social">
        </el-table-column>
        <el-table-column label="data pedido" width="100">
          <template #default="{ row: order }">
            <span>
              {{
                order.created_at
                  ? dateFormatter.format(new Date(order.created_at))
                  : "-"
              }}
            </span>
            {{ " " }}
            <span>
              {{
                order.created_at
                  ? timeFormatter.format(new Date(order.created_at))
                  : "-"
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="data entrega" width="100">
          <template #default="{ row: order }">
            <span>
              {{
                order.delivered_at
                  ? dateFormatter.format(new Date(order.delivered_at))
                  : "NÃO ENTREGUE"
              }}
            </span>
            {{ " " }}
            <span>
              {{
                order.delivered_at
                  ? timeFormatter.format(new Date(order.delivered_at))
                  : ""
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="NF" prop="invoice.nfe"></el-table-column>
        <el-table-column
          label="valor"
          :formatter="
            ({ total_amount }) => currencyFormatter.format(Number(total_amount))
          "
        ></el-table-column>
        <el-table-column
          label="pago"
          :formatter="
            ({ payments }) =>
              currencyFormatter.format(sumPaidOrderPayments(payments))
          "
        ></el-table-column>
        <el-table-column label="estado" prop="status_formatted" width="150">
          <template #default="s">
            <el-tag :type="getStatusType(s.row)">{{
              s.row.status_formatted
            }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderService from "../services/orders";
import { generatePDF } from "../services/reports";
import {
  dateFormatter,
  currencyFormatter,
  timeFormatter,
  dateFormatterShort,
  dateFormatterLong,
} from "../utils/formatters";
import { notifyError, notifySuccess } from "../utils/notifiers";
import BaseInput from "../components/BaseInput.vue";

export default {
  name: "OrdersCompletedDailyPage",
  data: () => ({
    hasError: false,
    isLoading: true,
    orders: null,
    currencyFormatter,
    day: new Date(),
    dateFormatter,
    allowedFields: {},
    timeFormatter,
    dateFormatterShort,
    dateFormatterLong,
  }),
  components: { BaseInput },
  mounted() {
    this.fetchOrders();
  },
  watch: {
    day: function () {
      this.fetchOrders();
    },
  },
  computed: {
    Orders() {
      return this.orders || [];
    },
    OrderStats() {
      if (this.Orders?.length) {
        const delayed = this.Orders.filter((o) => this.isDelayed(o))?.length;
        const on_time = this.Orders.length - delayed;
        const producing = this.Orders.filter(
          (o) => o.status === "production"
        )?.length;
        const last_day = this.Orders.filter(
          (o) => !this.isDelayed(o) && this.hoursLeft(o.deliver_at) > 18
        )?.length;

        return {
          delayed,
          on_time,
          producing,
          last_day,
          total: this.Orders.length,
        };
      }

      return null;
    },
  },
  methods: {
    allowUserUpdate(uid) {
      this.allowedFields[uid] = true;
    },
    canUpdate({ uid }) {
      return !!this.allowedFields[uid];
    },
    async updateOrder(orderData) {
      if (orderData) {
        const { order, error } = await OrderService(orderData.uid).update(
          orderData
        );

        if (error)
          notifyError("Ocorreu um erro ao atualizar o vínculo", error.message);
        else {
          notifySuccess(order.message);
        }
      }

      this.allowedFields[orderData.uid] = false;
    },
    executeUserCommand(command) {
      switch (command) {
        case "OrderByCreatedAt":
          this.createPDF({ created_at: false }, "date");
          break;
        case "OrderByInvoiceId":
          this.createPDF({ invoice_id: true }, "number");
          break;
      }
    },
    convertOrderValuesByType(first, second, key, orderByType) {
      let f, s;
      switch (orderByType) {
        case "date":
          f = new Date(first[key]);
          s = new Date(second[key]);
          break;
        case "number":
          f = Number(first[key]);
          s = Number(second[key]);
          break;
        default:
          f = first[key];
          s = second[key];
      }

      return { f, s };
    },
    generatePDFContent(orderBy, orderByType) {
      return this.Orders.sort((first, second) => {
        const [[key, asc]] = Object.entries(orderBy);

        const { f, s } = this.convertOrderValuesByType(
          first,
          second,
          key,
          orderByType
        );

        if (asc) return f < s ? -1 : 1;
        else f > s ? -1 : 1;
      }).map(
        ({
          invoice_id,
          seq,
          code,
          seller,
          client,
          created_at,
          delivered_at,
          invoice,
          total_amount,
          status_formatted,
          payments,
        }) => [
          invoice_id || " ",
          seq?.toString() || " ",
          code?.toString() || " ",
          seller?.name || " ",
          client?.legal?.social_name || client?.name || " ",
          `${this.dateFormatter.format(
            new Date(created_at)
          )}\n  ${this.timeFormatter.format(new Date(created_at))}`,

          (delivered_at &&
            `${this.dateFormatter.format(
              new Date(delivered_at)
            )}\n  ${this.timeFormatter.format(new Date(delivered_at))}`) ||
            " ",
          invoice?.nfe || " ",
          this.currencyFormatter.format(Number(total_amount)),
          this.currencyFormatter.format(this.sumPaidOrderPayments(payments)),
          status_formatted || " ",
        ]
      );
    },
    generateTableHeaders() {
      return [
        [
          "romaneio",
          "código",
          "pedido",
          "vendedor",
          "cliente",
          "data pedido",
          "data entrega",
          "nf",
          "valor",
          "pago",
          "estado",
        ],
      ];
    },
    createPDF(orderBy, orderByType) {
      const headers = this.generateTableHeaders();
      const content = this.generatePDFContent(orderBy, orderByType);

      const headerMessage = `Livro Diário - ${this.dateFormatter.format(
        this.day
      )}`;

      generatePDF({
        fileName: `Livro Diário - ${this.dateFormatter
          .format(this.day)
          .replace(/\//g, "-")}.pdf`,
        headerMessage,
        headers,
        content,
      });
    },
    isDelayed(order) {
      return order.deliver_at < new Date().toISOString();
    },
    getStatusType(order) {
      if (
        this.isDelayed(order) &&
        ["created", "accepted", "production", "delayed"].includes(order.status)
      )
        order.status = "delayed";

      if (order.deleted_at) order.status = "canceled";
      return ORDER_STATUS_TYPES[order.status];
    },
    buildTimeFilter() {
      const from = new Date(this.day.getTime());
      const aDayInMiliseconds = 8.64e7;
      from.setHours(0, 0, 0, 0);

      const to = new Date(from.getTime() + aDayInMiliseconds - 1);

      return { from, to };
    },
    async fetchOrders() {
      this.isLoading = true;

      const { from, to } = this.buildTimeFilter();

      const { orders } = await OrderService().index({
        status: "completed,finalized,canceled,delivery,delivered,paid",
        deleted_at: new Date(),
        checked_at_start: from.toISOString(),
        checked_at_end: to.toISOString(),
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
    sumPaidOrderPayments(payments) {
      return (
        payments?.reduce(
          (t, { amount, paid_at }) => (paid_at ? t + Number(amount) : t),
          0
        ) || 0
      );
    },
  },
};
const ORDER_STATUS_TYPES = {
  created: "info",
  production: null,
  analisys: "warning",
  accepted: null,
  ready: null,
  delayed: "danger",
  canceled: "danger",
  delivered: "success",
  completed: "info",
  finalized: "success",
};
</script>
<style scoped>
.el-card {
  width: 100%;
}

.mt-1 {
  margin-top: 5px;
}
</style>
